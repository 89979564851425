<template>
  <div class="d-flex align-items-center">
    <div
      v-html="getValue(consistency)"
      v-for="(consistency, consistencyIndex) in user.consistencies"
      :key="`key-${consistencyIndex}`"
      :class="getStyle(consistency)"
      class="w-50 pe-2 text-nowrap"
    ></div>
    <UserProgramDue v-if="showProgramDue && user.dueDate" :dueDate="user.dueDate"></UserProgramDue>
  </div>
</template>

<script>
export default {
  name: 'UserConsistency',
  components: {
    UserProgramDue: () => import('@/components/UserProgramDue'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    showProgramDue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getValue(c) {
      const { total, percentage } = c;
      const value = Math.round(percentage);
      if (total === 0) return '&mdash;';
      return `${value}%`;
    },
    getStyle(c) {
      const { total, percentage } = c;
      if (total === 0) return 'is-busy';
      if (percentage < 75) return 'text-danger';
      return '';
    },
  },
};
</script>
